import 'slick-slider';
import $ from 'jquery';

$('.slider-inner').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: $('.slider-controls-button-prev'),
    nextArrow: $('.slider-controls-button-next'),
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ]
});